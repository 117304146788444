// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-cinematography-index-js": () => import("./../../../src/pages/portfolio/cinematography/index.js" /* webpackChunkName: "component---src-pages-portfolio-cinematography-index-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-photography-ads-index-js": () => import("./../../../src/pages/portfolio/photography/ads/index.js" /* webpackChunkName: "component---src-pages-portfolio-photography-ads-index-js" */),
  "component---src-pages-portfolio-photography-ads-ladlink-intl-sch-js": () => import("./../../../src/pages/portfolio/photography/ads/ladlink-intl-sch.js" /* webpackChunkName: "component---src-pages-portfolio-photography-ads-ladlink-intl-sch-js" */),
  "component---src-pages-portfolio-photography-ads-star-gh-js": () => import("./../../../src/pages/portfolio/photography/ads/star-gh.js" /* webpackChunkName: "component---src-pages-portfolio-photography-ads-star-gh-js" */),
  "component---src-pages-portfolio-photography-ads-very-ghanaian-js": () => import("./../../../src/pages/portfolio/photography/ads/very-ghanaian.js" /* webpackChunkName: "component---src-pages-portfolio-photography-ads-very-ghanaian-js" */),
  "component---src-pages-portfolio-photography-ads-watch-hub-js": () => import("./../../../src/pages/portfolio/photography/ads/watch-hub.js" /* webpackChunkName: "component---src-pages-portfolio-photography-ads-watch-hub-js" */),
  "component---src-pages-portfolio-photography-documentary-bead-the-change-js": () => import("./../../../src/pages/portfolio/photography/documentary/bead-the-change.js" /* webpackChunkName: "component---src-pages-portfolio-photography-documentary-bead-the-change-js" */),
  "component---src-pages-portfolio-photography-documentary-distant-relative-js": () => import("./../../../src/pages/portfolio/photography/documentary/distant-relative.js" /* webpackChunkName: "component---src-pages-portfolio-photography-documentary-distant-relative-js" */),
  "component---src-pages-portfolio-photography-documentary-imf-js": () => import("./../../../src/pages/portfolio/photography/documentary/imf.js" /* webpackChunkName: "component---src-pages-portfolio-photography-documentary-imf-js" */),
  "component---src-pages-portfolio-photography-documentary-index-js": () => import("./../../../src/pages/portfolio/photography/documentary/index.js" /* webpackChunkName: "component---src-pages-portfolio-photography-documentary-index-js" */),
  "component---src-pages-portfolio-photography-documentary-rajwa-company-lance-js": () => import("./../../../src/pages/portfolio/photography/documentary/rajwa-company-lance.js" /* webpackChunkName: "component---src-pages-portfolio-photography-documentary-rajwa-company-lance-js" */),
  "component---src-pages-portfolio-photography-events-becon-intl-sch-js": () => import("./../../../src/pages/portfolio/photography/events/becon-intl-sch.js" /* webpackChunkName: "component---src-pages-portfolio-photography-events-becon-intl-sch-js" */),
  "component---src-pages-portfolio-photography-events-cocktail-co-js": () => import("./../../../src/pages/portfolio/photography/events/cocktail-co.js" /* webpackChunkName: "component---src-pages-portfolio-photography-events-cocktail-co-js" */),
  "component---src-pages-portfolio-photography-events-coperate-events-js": () => import("./../../../src/pages/portfolio/photography/events/coperate-events.js" /* webpackChunkName: "component---src-pages-portfolio-photography-events-coperate-events-js" */),
  "component---src-pages-portfolio-photography-events-esi-spice-cooking-class-js": () => import("./../../../src/pages/portfolio/photography/events/esi-spice-cooking-class.js" /* webpackChunkName: "component---src-pages-portfolio-photography-events-esi-spice-cooking-class-js" */),
  "component---src-pages-portfolio-photography-events-garden-party-js": () => import("./../../../src/pages/portfolio/photography/events/garden-party.js" /* webpackChunkName: "component---src-pages-portfolio-photography-events-garden-party-js" */),
  "component---src-pages-portfolio-photography-events-index-js": () => import("./../../../src/pages/portfolio/photography/events/index.js" /* webpackChunkName: "component---src-pages-portfolio-photography-events-index-js" */),
  "component---src-pages-portfolio-photography-index-js": () => import("./../../../src/pages/portfolio/photography/index.js" /* webpackChunkName: "component---src-pages-portfolio-photography-index-js" */),
  "component---src-pages-portfolio-photography-portraits-index-js": () => import("./../../../src/pages/portfolio/photography/portraits/index.js" /* webpackChunkName: "component---src-pages-portfolio-photography-portraits-index-js" */)
}

