export default {
  colors: {
    goldPrimary: '#ffae00',
    yellowPrimary: '#FFC001',
    darkBluePrimary: '#2D3748',
    darkBlueLighter2x: '#1A202C',
    darkBlueLighter3x: '#373747',
    greyPrimary: '#4A5568',
    greyLighter1x: '#9C9D9D',
    greyLighter2x: '#C1C1C1',
    greyLighter3x: '#C4C4C4',
    greyLighter4x: '#CBCBCB',
    greyLighter5x: '#ECEFF0',
    greyLighter6x: '#F8F8F8',
    white: '#ffffff',
    darkCream: '#e5ceb8',
    black: 'black',
  },
};
