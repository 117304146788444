import React from 'react';
import { theme } from 'src/theme';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'src/theme';

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </>
  );
};

export const onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    await import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};
