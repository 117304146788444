import { createGlobalStyle } from 'styled-components';
import whitney from '../../static/fonts/whitney.ttf';
import asul from '../../static/fonts/asul.woff2';
import theme from './theme';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Whitney';
    src: url(${whitney});
  }

  @font-face {
    font-family: 'Asul';
    src: url(${asul});
  }

  :root {
    --gutter: 20px;
  }

  body {
    font-family: 'Whitney', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
      Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    scroll-behavior: smooth;
    margin: 0;
    color: ${theme.colors.darkBluePrimary};
    -webkit-font-smoothing: antialiased;
    overflow-y: scroll;
  }
  img,
  picture {
    pointer-events: none;
  }
  .header,
  h1,
  h2,
  h3,
  h4 {
    font-family: 'Asul', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
      Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #2d3748;
  }
  .sticky {
    position: sticky;
    top: 0;
    width: 100%;
  }
  html {
    margin: 0;
    padding: 0;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll;
    overscroll-behavior-y: none;
  }
  .container-lg {
    max-width: 1440px;
    margin: 0 auto;

    @media only screen and (max-width: 600px) {
      padding: 10px;
      width: 100%;
      overflow: hidden;
    }
  }
  .container {
    padding: 0 100px;
    @media only screen and (max-width: 600px) {
      padding: 10px;
      width: 100%;
      overflow: hidden;
    }
  }
  button {
    cursor: pointer;
  }
  ul {
    li {
      list-style: none;
    }
  }
  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ff0000;
  }
  * {
    margin: 0;
    box-sizing: border-box;
    padding: 0;
  }
  a {
    text-decoration: none;
  }
  .pointer {
    cursor: pointer;
  }
  #nprogress {
    pointer-events: none;
  }
  #nprogress .bar {
    background: white;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
  }
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }
  #nprogress .spinner {
    position: fixed;
    z-index: 1031;
    bottom: 20px;
    right: 20px;
  }
  #nprogress .spinner-icon {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: ${theme.colors.white};
    border-left-color: ${theme.colors.white};
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
  }
  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }
  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }
  @-webkit-keyframes nprogress-spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes nprogress-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flex {
    display: flex;
    flex: 1;
  }

  .imagebox {
    .circle {
      width: 60px;
      height: 60px;
      position: absolute;
      top: -30px;
      left: -30px;
      transform: translateX(-30px) translateY(-30px);
      pointer-events: none;
      z-index: 9999;
      -webkit-transition: opacity 0.6s ease-in-out;
      transition: opacity 0.6s ease-in-out;
    }
    .circle svg {
      width: 100%;
      position: fixed;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      -webkit-transition: 600ms all cubic-bezier(0.23, 1, 0.32, 1);
      transition: 600ms all cubic-bezier(0.23, 1, 0.32, 1);
    }
    .circle svg path {
      width: 20px;
      height: 20px;
      -webkit-transition: 600ms all cubic-bezier(0.23, 1, 0.32, 1);
      transition: 600ms all cubic-bezier(0.23, 1, 0.32, 1);
    }
  }
  .horizontal-grid {
    position:relative;
    display: grid;
    grid-gap: var(--gutter) 0;
    grid-template-columns: var(--gutter) 1fr var(--gutter);
    align-content: start;
  }
  .horizontal-full {
    grid-column: 1 / -1;
  }
  .hs {
    max-height: 700px;
    display: grid;
    grid-gap: calc(var(--gutter) / 2);
    grid-template-columns: 10px;
    grid-template-rows: minmax(100px, 1fr);
    grid-auto-flow: column;
    grid-auto-columns: calc(50% - var(--gutter) * 2);
    grid-column: calc(50%);
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    padding-bottom: calc(0.75 * var(--gutter));
    margin-bottom: calc(-0.25 * var(--gutter));
    scrollbar-width: none;
    margin-bottom: 0;
    padding-bottom: 0;

    @media only screen and (max-width: 600px) {
      grid-auto-columns: calc(100% - var(--gutter) * 2);
      grid-column: calc(100%);
      max-height: 800px;
    }

    ::-webkit-scrollbar {
      display: none;
    }

    ::before,
    ::after {
      content: '';
      width: 10px;
    }
  }

  .has-grayscale {
    filter: grayscale(1);

    :hover {
      filter: grayscale(0);
    }
  }

  .portriat {
    width: calc(35% - var(--gutter) * 2);
  }

  .portriats {
    position: relative;
    grid-auto-columns: calc(35% - var(--gutter) * 2);
    height: 800px;

    @media only screen and (max-width: 600px) {
      grid-auto-columns: calc(100% - var(--gutter) * 2);
      grid-column: calc(100%);
      max-height: 600px;
    }
  }

  .modal-video-movie-wrap {
    border: 1px solid;
    padding-bottom: 0 !important;
  }

  .reverse {
    @media only screen and (max-width: 600px) {
      flex-wrap: wrap;
      margin-bottom: 30px;
    }
  }

  .teamGallery-container {
    @media only screen and (max-width: 600px) {
      display: flex !important;
      width: 100%;

      .team-member {
        flex-basis: 50%;
        width: auto;
        height: 400px;
      }
    }
  }

  .vimeo-container {
    padding: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }
  iframe {
    box-sizing: border-box;
    width: 177.77777778vh;
    height: 56.25vw;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ytplayer {
    pointer-events: none;
    position: absolute;
  }

  .navbar-bg { 
    backdrop-filter: saturate(180%) blur(250px);
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);

    a {
      color: ${theme.colors.darkBluePrimary}; 
    }

    .navItemActive {
      color: ${theme.colors.goldPrimary};
    }
  }

  html {
  font-family: sans-serif;
  scroll-behavior: smooth;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
progress {
  vertical-align: baseline;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline-width: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
button,
input,
optgroup,
select,
textarea {
  font: inherit;
  margin: 0;
}
optgroup {
  font-weight: 700;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type='reset'],
[type='submit'],
button,
html [type='button'] {
  -webkit-appearance: button;
}
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
html {
  font: 112.5%/1.45em georgia, serif;
  box-sizing: border-box;
  overflow-y: scroll;
}
* {
  box-sizing: inherit;
}
*:before {
  box-sizing: inherit;
}
*:after {
  box-sizing: inherit;
}
body {
  color: hsla(0, 0%, 0%, 0.8);
  font-family: georgia, serif;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  font-feature-settings: 'kern', 'liga', 'clig', 'calt';
}
img {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
h1 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  color: inherit;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 2.25rem;
  line-height: 1.1;
}
h2 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  color: inherit;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-size: 1.62671rem;
  line-height: 1.1;
}
h3 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  color: inherit;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.38316rem;
  line-height: 1.1;
}
h4 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  color: inherit;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1rem;
  line-height: 1.1;
}
h5 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;

  color: inherit;

  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.85028rem;
  line-height: 1.1;
}
h6 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  color: inherit;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.78405rem;
  line-height: 1.1;
}
hgroup {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
ul {
  margin-left: 1.45rem;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;

  list-style-position: outside;
  list-style-image: none;
}
ol {
  margin-left: 1.45rem;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;

  list-style-position: outside;
  list-style-image: none;
}
dl {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
dd {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
p {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
figure {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
pre {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;

  font-size: 0.85rem;
  line-height: 1.42;
  background: hsla(0, 0%, 0%, 0.04);
  border-radius: 3px;
  overflow: auto;
  word-wrap: normal;
  padding: 1.45rem;
}
table {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;

  font-size: 1rem;
  line-height: 1.45rem;
  border-collapse: collapse;
  width: 100%;
}
fieldset {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
blockquote {
  margin-left: 1.45rem;
  margin-right: 1.45rem;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
form {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
noscript {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
iframe {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
hr {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: calc(1.45rem - 1px);
  background: hsla(0, 0%, 0%, 0.2);
  border: none;
  height: 1px;
}
address {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
b {
  font-weight: bold;
}
strong {
  font-weight: bold;
}
dt {
  font-weight: bold;
}
th {
  font-weight: bold;
}
li {
  margin-bottom: calc(1.45rem / 2);
}
ol li {
  padding-left: 0;
}
ul li {
  padding-left: 0;
}
li > ol {
  margin-left: 1.45rem;
  margin-bottom: calc(1.45rem / 2);
  margin-top: calc(1.45rem / 2);
}
li > ul {
  margin-left: 1.45rem;
  margin-bottom: calc(1.45rem / 2);
  margin-top: calc(1.45rem / 2);
}
blockquote *:last-child {
  margin-bottom: 0;
}
li *:last-child {
  margin-bottom: 0;
}
p *:last-child {
  margin-bottom: 0;
}
li > p {
  margin-bottom: calc(1.45rem / 2);
}
code {
  font-size: 0.85rem;
  line-height: 1.45rem;
}
kbd {
  font-size: 0.85rem;
  line-height: 1.45rem;
}
samp {
  font-size: 0.85rem;
  line-height: 1.45rem;
}
abbr {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
}
acronym {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
}
abbr[title] {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
  text-decoration: none;
}
thead {
  text-align: left;
}
td,
th {
  text-align: left;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
  font-feature-settings: 'tnum';
  -moz-font-feature-settings: 'tnum';
  -ms-font-feature-settings: 'tnum';
  -webkit-font-feature-settings: 'tnum';
  padding-left: 0.96667rem;
  padding-right: 0.96667rem;
  padding-top: 0.725rem;
  padding-bottom: calc(0.725rem - 1px);
}
th:first-child,
td:first-child {
  padding-left: 0;
}
th:last-child,
td:last-child {
  padding-right: 0;
}
tt,
code {
  background-color: hsla(0, 0%, 0%, 0.04);
  border-radius: 3px;
  font-family: 'SFMono-Regular', Consolas, 'Roboto Mono', 'Droid Sans Mono', 'Liberation Mono',
    Menlo, Courier, monospace;
  padding: 0;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}
pre code {
  background: none;
  line-height: 1.42;
}
code:before,
code:after,
tt:before,
tt:after {
  letter-spacing: -0.2em;
  content: ' ';
}
pre code:before,
pre code:after,
pre tt:before,
pre tt:after {
  content: '';
}
@media only screen and (max-width: 480px) {
  html {
    font-size: 100%;
  }
}


.menu-item {
  padding: 0 40px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
}
.menu-item-wrapper.active {
  border: 1px blue solid;
}
.menu-item.active {
  border: 1px green solid;
}
 
.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

  

`;
export default GlobalStyles;
